<template>
  <div class="csn-loader"><Loading /></div>
</template>

<script>
import { LOADER_COMPONENT } from '@/constants'

export default {
  name: LOADER_COMPONENT,
  components: {
    Loading: () => import("@/components/atoms/Loading"),
  },
};
</script>
